import React from 'react';

import { Card, CardBody, Form, Row, Col, Spinner } from 'reactstrap';

import icon_whatsapp from "../../assets/images/icon-wtsapp.svg";
import icon_cpr_physio from "../../assets/images/icon-cpr-physio.png";
import icon_grand_living from "../../assets/images/icon-grand-living.png";
import icon_show_me_beauty from "../../assets/images/icon-show-me-beauty.png";
import icon_aoh from "../../assets/images/icon-aoh.png";
import icon_zomate from "../../assets/images/icon-zomate.png";
import icon_hangry_buddy from "../../assets/images/icon-hangry-buddy.png";
import icon_skcare from "../../assets/images/icon-skcare.png";


import sis_price_label from "../../assets/images/sis-price-label.svg";
import original_price_label from "../../assets/images/original-price-label.svg";

import _ from 'lodash';

const Reward = ({ brand, star }) => {
  const brandlist = [
    {
      key: "cpr",
      logo: icon_cpr_physio,
      title: "CPR Physio",
      caption: "體質管理中心",
      address: `旺角東亞銀行旺角大廈六樓全層<br/>西環香港商業中心3711室<br/>朗豪坊辦公大樓1012室`,
      rewards: [
        {
          content: `<h5 class="reward-name">隔空增肌或隔空溶脂 二選一</h5><p class="reward-desc">消除痛症，訓住健康瘦</p>`,
          price: "680",
          original: "1,180",
          whatsapp: "5599 6679"
        },
        {
          content: `<h5 class="reward-name">隔空增肌</h5><h5 class="reward-name">隔空溶脂</h5><p class="reward-desc">消除痛症，訓住健康瘦 1+1</p>`,
          price: "880",
          original: "1,960",
          whatsapp: "5599 6679"
        },
        {
          content: `<h5 class="reward-name">隔空增肌</h5>
          <h5 class="reward-name">隔空溶脂</h5>
          <h5 class="reward-name">氣壓淋巴水腫循環治療</h5>
          <h5 class="reward-name">免費姿勢痛症咨詢服務Moti physio 乙次(價值$480)</h5>`,
          price: "980",
          original: "3,980",
          whatsapp: "5599 6679"
        },
      ]
    },
    {
      key: "grand-living",
      logo: icon_grand_living,
      title: "Grand Living",
      caption: "結構治療",
      address: `中環德輔道中84-86號章記大廈903室`,
      rewards: [
        {
          content: `<h5 class="reward-name">矯正副乳、bye bye 肉達到心形胸的效果</h5><p class="reward-desc">消提升胸部線條、減少副乳</p>`,
          price: "380",
          original: "680",
          whatsapp: "9305 9182"
        },
        {
          content: `<h5 class="reward-name">內臟按摩</h5><p class="reward-desc">改善胃腩、根治經痛、排毒功能，可收腰線，肋骨高低問題</p>`,
          price: "480",
          original: "800",
          whatsapp: "9305 9182"
        },
        {
          content: `<h5 class="reward-name">結構治療</h5><p class="reward-desc">矯正脊椎、消除痛症、長短腳、盤骨前傾等問題</p>`,
          price: "680",
          original: "1,000",
          whatsapp: "9305 9182"
        },
      ]
    },
    {
      key: "show-me-beauty",
      logo: icon_show_me_beauty,
      title: "尚美堂",
      caption: "美容養生",
      address: `尖沙咀廣東道54至66號帝國大廈14樓A室`,
      rewards: [
        {
          content: `<h5 class="reward-name">砭石提拉緊緻面部護理</h5><p class="reward-desc">疏通經絡，滋潤肌膚<br>送海藻亮澤蠶絲面膜乙盒 6pc (價值$188)</p>`,
          price: "340",
          original: "780",
          whatsapp: "6712 1527"
        },
        {
          content: `<h5 class="reward-name">暖宮平衡護理</h5><p class="reward-desc">驅寒去濕，改善月事不適<br>砭石提拉緊緻面部護理<br>疏通經絡，滋潤肌膚<br>送海藻亮澤蠶絲面膜乙盒 6pc (價值$188)</p>`,
          price: "580",
          original: "1,660",
          whatsapp: "6712 1527"
        },
        {
          content: `<h5 class="reward-name">法國細胞排毒溶脂氈</h5><p class="reward-desc">改善水腫及肥胖，提升代謝<br>砭石提拉緊緻面部護理<br>疏通經絡，滋潤肌膚<br>送海藻亮澤蠶絲面膜乙盒 6pc (價值$188)</p>`,
          price: "780",
          original: "2,380",
          whatsapp: "6712 1527"
        },
      ]
    },
    {
      key: "aoh",
      logo: icon_aoh,
      title: "AOh！",
      caption: "專業紋繡",
      address: `鑽石山彩虹道212號The Burrow 1207室<br/>荔枝角大南西街東方國際大廈1504室`,
      rewards: [
        {
          content: `<h5 class="reward-name">個人化專屬眉形設計包</h5><h5 class="reward-name">輕氧眉服務連補色 9 折優惠</h5>`,
          price: "2,250",
          original: "2,500",
          whatsapp: "9051 1978"
        },
        {
          content: `<h5 class="reward-name">個人化專屬眉形設計包輕氧眉服務連補色 85 折優惠</h5><h5 class="reward-name">SMP再生紋髮術 9 折優惠</h5><p class="reward-desc">解決男女士頭髮稀疏問題，即時有效、自然濃密</p>`,
          price: "2,125",
          original: "2,500",
          whatsapp: "9051 1978"
        },
        {
          content: `<h5 class="reward-name">個人化專屬眉形設計包輕氧眉服務連補色 8 折優惠</h5><h5 class="reward-name">SMP再生紋髮術 9 折優惠</h5><h5 class="reward-name">送日本科技育髮 AI 頭皮檢測一次 (原價$388)</h5>`,
          price: "2,000",
          original: "2,500",
          whatsapp: "9051 1978"
        },
      ]
    },
    {
      key: "zomate",
      logo: icon_zomate,
      title: "Zomate Fitness",
      caption: "女性健身室",
      address: `尖沙咀柯士甸102號22樓全層<br/>上環宏基商業大廈一樓全層`,
      rewards: [
        {
          content: `<h5 class="reward-name">皇牌女性健身1:1 PT 首堂半價</h5><p class="reward-desc">增肌減脂/ 婚前修身/ 產前產後</p>`,
          price: "380",
          original: "760",
          whatsapp: "6052 9291"
        },
        {
          content: `<h5 class="reward-name">皇牌女性健身1:1 PT</h5><p class="reward-desc">增肌減脂/ 婚前修身/ 產前產後<br>報名1:1課程 10堂<br>獨家$500 現金回贈</p>`,
          price: "2,125",
          original: "2,500",
          whatsapp: "6052 9291"
        },
        {
          content: `<h5 class="reward-name">皇牌女性健身1:1 PT</h5><p class="reward-desc">增肌減脂/ 婚前修身/ 產前產後<br>報名1:1課程 10堂<br>獨家$600 現金回贈</p>`,
          price: "2,000",
          original: "2,500",
          whatsapp: "6052 9291"
        },
      ]
    },
    {
      key: "hangry-buddy",
      logo: icon_hangry_buddy,
      title: "Hangry Buddy",
      caption: "蒟蒻低卡食品",
      rewards: [
        {
          content: `<h5 class="reward-name">網站消費，送Hangry Buddy原味蒟蒻一包（隨機）</h5>
          <p class="reward-desc">付款時請輸入<br><span class="fs-2">sisxahb1#</span></p>`,
          price: "",
          original: "",
          website: `網上訂購：<br/><a href="http://www.hangrybuddy.com" target="_blank">www.hangrybuddy.com</a>`
        },
        {
          content: `<h5 class="reward-name">網站消費滿$500可以使用現金券$50</h5>
          <p class="reward-desc">付款時請輸入<br><span class="fs-2">sismaxhb2#</span></p>`,
          price: "2,125",
          original: "2,500",
          website: `網上訂購：<br/><a href="http://www.hangrybuddy.com" target="_blank">www.hangrybuddy.com</a>`
        },
        {
          content: `<h5 class="reward-name">網站消費滿$600可以使用現金券$100</h5>
          <p class="reward-desc">付款時請輸入<br><span class="fs-2">amsispm3#</span></p>`,
          price: "2,000",
          original: "2,500",
          website: `網上訂購：<br/><a href="http://www.hangrybuddy.com" target="_blank">www.hangrybuddy.com</a>`
        },
      ]
    },
    {
      key: "skcare",
      logo: icon_skcare,
      title: "世嘉專科醫療",
      caption: "女性專科醫療",
      address: `尖沙咀漢口道28號亞太中心14樓1401至1406室`,
      rewards: [
        {
          // empty
        },
        {
          content: `<h5 class="reward-name">乳房檢查計劃</h5><p class="reward-desc">專科醫生病歷評估及乳房檢查(根據個人情況+/-臨床超聲波檢查)<br>#其後每次覆診，手術/檢查/化驗等費用另計</p>`,
          price: "800",
          original: "",
          whatsapp: "9028 3814"
        },
        {
          content: `<h5 class="reward-name">乳房檢查計劃</h5><p class="reward-desc">專科醫生病歷評估及乳房檢查(根據個人情況+/-臨床超聲波檢查)<br>#其後每次覆診，手術/檢查/化驗等費用另計</p>`,
          price: "800",
          original: "",
          whatsapp: "9028 3814"
        },
      ]
    }
  ]

  const currentBrand = brandlist.find(b => b.key === brand);
  const currentReward = currentBrand?.rewards[star - 1] || null;

  if(!currentReward || !currentReward.content){
    return (<></>);
  }

  return (
    <Card className="brand">
      <CardBody>
        <Row className="align-items-center">
          <Col xs={4}>
            <img src={currentBrand.logo} alt={currentBrand.title} />
          </Col>
          <Col>
            <h5 className="brand-title">{currentBrand.title}</h5>
            <p className="brand-caption">{currentBrand.caption}</p>
            <p className="brand-address" dangerouslySetInnerHTML={{ __html: currentBrand.address }}></p>
          </Col>
        </Row>
        <hr />
        {currentReward && (
          <>
            <div dangerouslySetInnerHTML={{ __html: currentReward.content }}></div>
            {currentReward.whatsapp && (
              <div class="whatsapp-contact">
                <img src={icon_whatsapp} />
                <a href={`https://wa.me/852${currentReward.whatsapp.replace(/\s/g, '')}`} target="_blank">{currentReward.whatsapp}</a>
              </div>
            )}
            {currentReward.website && (
              <div class="website-contact" dangerouslySetInnerHTML={{ __html: currentReward.website }}></div>
            )}
            {currentReward.price && (
              <div className="price">
                <div className="sis" style={{ backgroundImage: `url(${sis_price_label})` }}>{currentReward.price}</div>
                <div></div>
                {currentReward.original && <div className="original" style={{ backgroundImage: `url(${original_price_label})` }}>{currentReward.original}</div>}
              </div>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default Reward;