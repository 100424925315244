import React, { useState, useCallback, useEffect } from 'react';

const Frame = (props) => {
  return (
    <React.Fragment>
      <div id="page">
        <div className="main-content app-content">{props.children}</div>
      </div>
    </React.Fragment>
  );
}

export default Frame;