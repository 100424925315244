import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import sis_title from "../../assets/images/sis-title.svg";
import intro_bg from "../../assets/images/intro-bg.png";
import sis_slogan from "../../assets/images/sis-slogan.svg";
import arrow_start from "../../assets/images/arrow-start.svg";

import sis_logo from "../../assets/images/sis-logo.svg";
import q_bg from "../../assets/images/q-bg.png";
import hk_flag from "../../assets/images/hk-flag.svg";
import star from "../../assets/images/marks-star.svg";
import star_full from "../../assets/images/marks-star-full.svg";

import icon_fb from "../../assets/images/icon-fb.svg";
import icon_ig from "../../assets/images/icon-ig.svg";

import download_img from "../../assets/images/A1.jpg";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import classnames from 'classnames';
import _ from 'lodash';

import { Card, CardBody, Form, Row, Col, Spinner } from 'reactstrap';

import Reward from './Reward';

// action
import { submitSurvey } from "../../store/actions";

const Survey = () => {
  const dispatch = useDispatch();

  const { error, submitSuccess, submitLoading } = useSelector(state => ({
    submitSuccess: state.Survey.submitSuccess,
    submitLoading: state.Survey.submitLoading,
    error: state.Survey.error,
  }));

  const [step, setStep] = useState("start");
  const [currentQ, setCurrentQ] = useState(1);
  const [result, setResult] = useState(null);
  const question = [
    {
      title: "經過一天的繁忙，回家第一件事你會做甚麼？",
      options: [
        { title: "A. 躺在梳化", value: "A", marks: 5 },
        { title: "B. 卸妝沖涼", value: "B", marks: 10 },
        { title: "C. 玩手機", value: "C", marks: 5 },
        { title: "D. 一點煙酒，放鬆一下", value: "D", marks: 0, reminder: "盡量少接觸煙酒，因為那是健康與美麗的最大殺手。" },
      ]
    },
    {
      title: "在辦公室裡，你認為如何能最好表現出自己的工作能力？",
      options: [
        { title: "A. 專注，可以兩小時不離開座位", value: "A", marks: 3, reminder: "久坐不動，筋膜就緊，建議每小時起一起身，飲杯水或去個廁所。" },
        { title: "B. 快，做事比別人更快完成", value: "B", marks: 5, reminder: "久坐不動，筋膜就緊，建議每小時起一起身，飲杯水或去個廁所。" },
        { title: "C. 注重細節，每件事都反覆檢視", value: "C", marks: 7, reminder: "久坐不動，筋膜就緊，建議每小時起一起身，飲杯水或去個廁所。" },
        { title: "D. 人緣好，經常到處跟不同人溝通", value: "D", marks: 10 },
      ]
    },
    {
      title: "在交通工具上，如果朋友傳來一段好看的短片，你會：",
      options: [
        { title: "A. 即時打開慢慢看", value: "A", marks: 3, reminder: "手機玩太多，肩膊、手都會不適，搭車最好閉目養神或看看遠方的風景。" },
        { title: "B. 閉目養神，之後再看", value: "B", marks: 10 },
        { title: "C. 先傳訊息問問有甚麼好看", value: "C", marks: 5, reminder: "手機玩太多，肩膊、手都會不適，搭車最好閉目養神或看看遠方的風景。" },
        { title: "D. 繼續玩手遊", value: "D", marks: 3, reminder: "手機玩太多，肩膊、手都會不適，搭車最好閉目養神或看看遠方的風景。" },
      ]
    },
    {
      title: "周末放假，你最喜歡的活動是？",
      options: [
        { title: "A. 參加運動班", value: "A", marks: 10 },
        { title: "B. 行商場", value: "B", marks: 5, reminder: "放假除了休息，都要放鬆心靈，活動一下筋骨。冷氣不要吹太多，吸收一下新鮮空氣，個人都會醒神好多。" },
        { title: "C. 行山", value: "C", marks: 10 },
        { title: "D. 在家休息", value: "D", marks: 5, reminder: "放假除了休息，都要放鬆心靈，活動一下筋骨。冷氣不要吹太多，吸收一下新鮮空氣，個人都會醒神好多。" },
      ]
    },
    {
      title: "你每天會喝多少水呢？",
      options: [
        { title: "A. 1-2杯", value: "A", marks: 3, reminder: "確保每天攝取足夠的水分，茶同咖啡並唔能夠代替，每小時飲200-300ml。記得不要待到唇 / 口乾才來灌水，灌水除了身體吸收不到，亦不能排走毒素，更會容易變水腫。" },
        { title: "B. 3-4杯", value: "B", marks: 5, reminder: "確保每天攝取足夠的水分，茶同咖啡並唔能夠代替，每小時飲200-300ml。記得不要待到唇 / 口乾才來灌水，灌水除了身體吸收不到，亦不能排走毒素，更會容易變水腫。" },
        { title: "C. 5-6杯", value: "C", marks: 8, reminder: "確保每天攝取足夠的水分，茶同咖啡並唔能夠代替，每小時飲200-300ml。記得不要待到唇 / 口乾才來灌水，灌水除了身體吸收不到，亦不能排走毒素，更會容易變水腫。" },
        { title: "D. 7-8杯", value: "D", marks: 10 },
      ]
    },
    {
      title: "和朋友聊天時，你最喜歡的坐姿是？",
      options: [
        { title: "翹腳", value: "A", marks: 5, reminder: "平時坐直，膝頭 90 度雙腳垂直，可以減少椎間盤移位、腰椎移位、骨刺等問題。" },
        { title: "盤腿坐", value: "B", marks: 5, reminder: "平時坐直，膝頭 90 度雙腳垂直，可以減少椎間盤移位、腰椎移位、骨刺等問題。" },
        { title: "坐直直", value: "C", marks: 10 },
        { title: "半躺在椅", value: "D", marks: 3, reminder: "平時坐直，膝頭 90 度雙腳垂直，可以減少椎間盤移位、腰椎移位、骨刺等問題。" },
      ]
    },
    {
      title: "在港鐵、巴士車廂或掛隊中，你通常會以哪一種方式站立？",
      options: [
        { title: "背部靠在車門/玻璃/扶手柱/牆身", value: "A", marks: 5, reminder: "搭車站立時雙腳同盆骨一致，微微屈膝，除了平𧗽，更可以減少日後膝關節痛。" },
        { title: "直立", value: "B", marks: 10 },
        { title: "翹腳企", value: "C", marks: 3, reminder: "搭車站立時雙腳同盆骨一致，微微屈膝，除了平𧗽，更可以減少日後膝關節痛。" },
        { title: "踎低", value: "D", marks: 3, reminder: "搭車站立時雙腳同盆骨一致，微微屈膝，除了平𧗽，更可以減少日後膝關節痛。" },
      ]
    },
    {
      title: "每晚臨睡前，在床上做的最後一件事是：",
      options: [
        { title: "玩手機 / 電腦 / 電視", value: "A", marks: 3, reminder: "避免在睡前使用3C設備，例如：手機、電腦、遊戲機，因為3C設備藍光會影響褪黑激素(Melatonin)分泌，從而影響睡眠品質。" },
        { title: "看書", value: "B", marks: 10 },
        { title: "傾電話", value: "C", marks: 5, reminder: "避免在睡前使用3C設備，例如：手機、電腦、遊戲機，因為3C設備藍光會影響褪黑激素(Melatonin)分泌，從而影響睡眠品質。" },
        { title: "冥想", value: "D", marks: 10 },
      ]
    },
    {
      title: "出街最喜歡用的手袋款式是？",
      options: [
        { title: "斜孭袋", value: "A", marks: 7, reminder: "手袋太重要用背包，可以平衡壓力，沒那麼容易頸緊膊痛。" },
        { title: "單邊孭袋", value: "B", marks: 5, reminder: "手袋太重要用背包，可以平衡壓力，沒那麼容易頸緊膊痛。" },
        { title: "背包", value: "C", marks: 10 },
        { title: "手提包", value: "D", marks: 5, reminder: "手袋太重要用背包，可以平衡壓力，沒那麼容易頸緊膊痛。" },
      ]
    },
    {
      title: "你步行最多的時候是？",
      options: [
        { title: "購物", value: "A", marks: 5, reminder: "每日最少步行 7000步，可以試試早一個站落車步行回家，長久會健康好多。" },
        { title: "行山", value: "B", marks: 10 },
        { title: "散步", value: "C", marks: 8 },
        { title: "往返工作地點", value: "D", marks: 3, reminder: "每日最少步行 7000步，可以試試早一個站落車步行回家，長久會健康好多。" },
      ]
    },
    {
      title: "除咗做家務同步行，一星期會做幾多日運動",
      options: [
        { title: "1-2日", value: "A", marks: 10 },
        { title: "3-4日", value: "B", marks: 15 },
        { title: "5-7日", value: "C", marks: 20 },
        { title: "唔做運動", value: "D", marks: 0, reminder: "適量運動除了可以強身健體，亦都可以令你心靈得到放鬆、減低壓力，所以建議抽少少時間做運動。" },
      ]
    },
    {
      title: "甚麼時候吃東西會讓你最愉快？",
      options: [
        { title: "宵夜", value: "A", marks: 3, reminder: "早餐係最重要，其他餐數七分飽就好啦。" },
        { title: "Lunch time", value: "B", marks: 5, reminder: "早餐係最重要，其他餐數七分飽就好啦。" },
        { title: "晚上", value: "C", marks: 7, reminder: "早餐係最重要，其他餐數七分飽就好啦。" },
        { title: "早上", value: "D", marks: 10 },
      ]
    },
    {
      title: "邊方面嘅飲食佔據你大多數嘅餐單",
      options: [
        { title: "食肉獸、拒絕蔬菜", value: "A", marks: 5, reminder: "飲食盡量少鹽、少糖、少油、少凍野，皺紋都會少一點！" },
        { title: "茶記常餐、米線、炒飯", value: "B", marks: 3, reminder: "飲食盡量少鹽、少糖、少油、少凍野，皺紋都會少一點！" },
        { title: "自己下廚、少鹽糖油", value: "C", marks: 10 },
        { title: "雪糕零食、凍飲汽水", value: "D", marks: 0, reminder: "飲食盡量少鹽、少糖、少油、少凍野，皺紋都會少一點！" },
      ]
    },
    {
      title: "對於現在的工作狀況，你的感覺是？",
      options: [
        { title: "真心開心，得到滿足感", value: "A", marks: 10 },
        { title: "煩躁不安，厭倦工作", value: "B", marks: 5, reminder: "注意壓力指數，透過練習深呼吸、冥想或增加 Me time，來幫助自己放鬆和減輕壓力。" },
        { title: "宮廷生活，皇帝老闆難服侍", value: "C", marks: 5, reminder: "注意壓力指數，透過練習深呼吸、冥想或增加 Me time，來幫助自己放鬆和減輕壓力。" },
        { title: "日夜顛倒，生活疲倦", value: "D", marks: 3, reminder: "注意壓力指數，透過練習深呼吸、冥想或增加 Me time，來幫助自己放鬆和減輕壓力。" },
      ]
    }
  ]

  const calculateMarks = () => {
    let marks = 0;
    let reminder = [];
    _.map([
      'question1',
      'question2',
      'question3',
      'question4',
      'question5',
      'question6',
      'question7',
      'question8',
      'question9',
      'question10',
      'question11',
      'question12',
      'question13',
      'question14',
    ], (value) => {
      const qNum = parseInt(value.replace("question", ""));
      const currentQ = question[qNum - 1];
      const currentOpt = currentQ.options.find(opt => opt.value === survey.values[value]);
      marks += currentOpt.marks;
      if (currentOpt.reminder) {
        reminder.push(currentOpt.reminder);
      }
    });

    setResult({
      marks: marks,
      reminder: reminder,
      star: marks >= 80 ? 3 : marks >= 60 ? 2 : 1
    })

    return {
      marks: marks,
      reminder: reminder,
      star: marks >= 80 ? 3 : marks >= 60 ? 2 : 1
    };
  }

  const clickShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          url: document.URL,
          title: document.title,
          text: "全港首個女性健康獎賞計劃"
        });
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      console.log("Web Share API is not supported in this browser.");
    }
  }

  const handleDownload = () => {
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = download_img;
    link.download = 'sis_discount.jpg'; // Specify the desired file name

    // Simulate a click on the link to trigger the download
    link.click();
  };

  const survey = useFormik({
    enableReinitialize: false,
    initialValues: {
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      phonenum: Yup.string().required("Phone number is required").matches(/^[0-9]+$/, "Phone number must be numeric"),
      email: Yup.string().email("Invalid email address").required("Email is required"),
    }),
    onSubmit: (values) => {
      const cal = calculateMarks();
      console.log(cal);
      dispatch(submitSurvey({
        ...values,
        marks: cal.marks,
      }));
    },
    validateOnChange: false, // Disable validation at the beginning
    validateOnBlur: false, // Disable validation at the beginning
  })

  useEffect(() => {
    if (submitSuccess) {
      setStep("result");
    }
  }, [submitSuccess, submitLoading, error]);

  useEffect(() => {
    const resizeFunction = () => {
      const appContent = document.querySelector('.app-content');
      const landingElem = document.querySelector('.body-wrap.introduction');

      if (!landingElem) {
        return;
      }

      const appContentWidth = appContent.offsetWidth;
      const ratio = 1160 / 600;

      const minHeight = appContentWidth * ratio;
      if (document.documentElement.clientHeight < minHeight) {
        landingElem.style.minHeight = `${minHeight}px`;
      } else {
        landingElem.style.minHeight = '';
      }
    };

    window.addEventListener('resize', resizeFunction);
    resizeFunction();

    // Cleanup function
    return () => {
      window.removeEventListener('resize', resizeFunction);
    };
  }, []);

  document.title = "全港首個女性健康獎賞計劃 | SIS!";

  return (
    <React.Fragment>
      <div className={classnames({
        "q-bg": true,
        "hidden": step === "start"
      })}><img src={q_bg} /></div>
      {step === 'start' && (
        <div className="body-wrap introduction">
          <img src={sis_title} title="全港首個女性健康獎賞計劃"></img>
          <div className="sis-slogan">
            <img src={sis_slogan} title="越健康越多現金回贈!健康習慣從此可以變現!" />
          </div>
          <div className="bg">
            <img src={intro_bg} />
          </div>
          <div className="btn-container">
            <div className="wrap">
              <button
                type="button"
                className="btn"
                onClick={() => setStep("questioning")}
              >馬上開始 <img src={arrow_start} className="arrow-start" /></button>
            </div>
          </div>
        </div>
      )}
      {(step === 'questioning' || step === 'insertInfo') && (
        <div className="body-wrap questioning">
          <header className="d-flex justify-content-between align-items-center">
            <img src={sis_logo} className="sis-logo" />
            <button
              className="back-btn"
              onClick={() => {
                if (step === 'questioning') {
                  if (currentQ <= 1) {
                    setStep("start")
                    setCurrentQ(1);
                  } else {
                    setCurrentQ(currentQ - 1);
                  }
                }
                if (step === 'insertInfo') {
                  setStep("questioning")
                }
              }}>
              <i className="ri-arrow-left-s-line"></i>
            </button>
            {step === 'questioning' && (
              <div className="current-q">{currentQ} / {question.length}</div>
            )}
          </header>
          <div className="progress-bar">
            <div className="bar" style={{ width: `${currentQ / question.length * 100}%` }}></div>
          </div>
          {step === 'questioning' && (
            <>
              <div className="q-area">
                <h6 className="q-title">{question[currentQ - 1].title}</h6>
                <p>選擇佔用您大部份時間的選項。</p>
                <div className="options">
                  {question[currentQ - 1].options.map((opt, i) => (
                    <div className="option" key={i}>
                      <input
                        type="radio"
                        className="btn-check"
                        id={`q-${currentQ},opt-${i}`}
                        name={`question${currentQ}`}
                        value={opt.value}
                        onChange={survey.handleChange}
                        checked={survey.values[`question${currentQ}`] === opt.value}
                      />
                      <label className="btn" htmlFor={`q-${currentQ},opt-${i}`}>{opt.title}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className={classnames({
                "btn-container": true,
                "disabled": !survey.values[`question${currentQ}`]
              })}>
                <div className="wrap">
                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      if (currentQ === question.length) {
                        setStep("insertInfo")
                      } else {
                        setCurrentQ(currentQ + 1)
                      }
                    }}
                    disabled={!survey.values[`question${currentQ}`]}
                  >繼續</button>
                </div>
              </div>
            </>
          )}
          {step === 'insertInfo' && (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                survey.handleSubmit();
                return false;
              }}>
              <div className="q-area">
                <h6 className="q-title">你的資料</h6>
                <p>資料只會用作領獎賞時核對身份。</p>
                <div className="form-group">
                  <label htmlFor="name" className="mb-1">姓名<sup>*</sup></label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    onChange={survey.handleChange}
                    value={survey.values.name}
                    invalid={survey.errors.name}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone" className="mb-1">電話號碼<sup>*</sup></label>
                  <div className={classnames({
                    "input-group phone-group": true,
                    "invalid": survey.errors.phonenum
                  })}>
                    <div className="input-group-prepend phone-prefix">
                      <div className="input-group-text">+852</div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="phonenum"
                      name="phonenum"
                      onChange={survey.handleChange}
                      value={survey.values.phonenum}
                      invalid={survey.errors.phonenum}
                      maxLength={8}
                    />
                    <div className="icon-flag">
                      <img src={hk_flag} />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="email" className="mb-1">電子郵件<sup>*</sup></label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    onChange={survey.handleChange}
                    value={survey.values.email}
                    invalid={survey.errors.email}
                  />
                </div>
                <div className="btn-container">
                  <div className="wrap">
                    <button
                      type="submit"
                      className="btn"
                      style={{ opacity: 1 }}
                      disabled={submitLoading}
                    >
                      {submitLoading ? <Spinner size="sm" className='loading-spinner'> 提交中... </Spinner> : <>查看結果及領取獎賞</>}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
          {
            process.env.NODE_ENV === 'development' && (
              <>
                <pre className="">survey: {JSON.stringify(survey.values, null, 2)}</pre>
              </>
            )
          }
        </div>
      )}
      {step === 'result' && (
        <>
          <div className="body-wrap result">
            <header className="d-flex justify-content-between align-items-center">
              <img src={sis_logo} className="sis-logo" />
            </header>
            <div className="result-area">
              <Card>
                <CardBody>
                  <div className="sis-label">全港首個女性健康獎賞計劃</div>
                  <div className="result-row">
                    <Row>
                      <Col xs={6}>
                        <div className="result-title">你的得分</div>
                        <div className="result-text">{result?.marks}</div>
                      </Col>
                      <Col xs={6}>
                        <div className="result-title">星級分數</div>
                        <div className="result-text">
                          <div className="star">
                            <img src={result.star >= 1 ? star_full : star} />
                          </div>
                          <div className="star">
                            <img src={result.star >= 2 ? star_full : star} />
                          </div>
                          <div className="star">
                            <img src={result.star >= 3 ? star_full : star} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
              {result?.reminder.length > 0 && (
                <Card>
                  <CardBody>
                    <p>根據您的生活習慣，我們發現您的健康狀況有待改善。<br />以下是一些建議，幫助您改善生活方式，促進健康：</p>
                    <ol className="custom-ol">
                      {result.reminder.map((rem, i) => (
                        <li key={i}>{rem}</li>
                      ))}
                    </ol>
                  </CardBody>
                </Card>
              )}
              <div className="cash-rewards">
                <h3 className="text-center reward-title">現金獎賞回贈</h3>
                <p className="mt-1 fs-4 text-center">可選多項</p>
                <div className="btn-container mt-3 mb-5">
                  <div className="wrap">
                    <button
                      type="button"
                      className="btn"
                      onClick={handleDownload}
                    >
                      下載所有優惠
                    </button>
                  </div>
                </div>
                <Reward brand="cpr" star={result.star} />
                <Reward brand="grand-living" star={result.star} />
                <Reward brand="show-me-beauty" star={result.star} />
                <Reward brand="aoh" star={result.star} />
                <Reward brand="zomate" star={result.star} />
                <Reward brand="hangry-buddy" star={result.star} />
                <Reward brand="skcare" star={result.star} />
              </div>
              <div className="btn-container">
                <div className="wrap">
                  <button
                    type="button"
                    className="btn"
                    onClick={handleDownload}
                  >
                    下載所有優惠
                  </button>
                </div>
              </div>
              <div className="btn-container mt-3">
                <div className="wrap">
                  <button
                    type="button"
                    className="btn"
                    onClick={clickShare}
                  >
                    分享給好友
                  </button>
                </div>
              </div>
              <div class="play-again-button">
                <a href="/">再玩一次？</a>
              </div>
            </div>
            {
              process.env.NODE_ENV === 'development' && (
                <>
                  <pre className="">result: {JSON.stringify(result, null, 2)}</pre>
                </>
              )
            }
          </div>
          <footer>
            <Row>
              <Col xs={8}>
                <label>關注我們</label>
                <a href="https://www.facebook.com/sishkhealth" target="_blank" style={{ backgroundImage: `url(${icon_fb})` }}>sishkhealth</a>
                <div></div>
                <a href="https://www.instagram.com/sishkhealth/" target="_blank" style={{ backgroundImage: `url(${icon_ig})` }}>sishkhealth</a>
              </Col>
              <Col>
                <img src={sis_logo} className="sis-logo" />
              </Col>
            </Row>
            <div className="copyright">COPYRIGHT&copy;SISHK 2024 ALL RIGHT RESERVED.</div>
          </footer>
        </>
      )}
    </React.Fragment>
  );
}

export default Survey;