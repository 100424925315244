import React from 'react';

import './assets/libs/bootstrap/css/bootstrap.min.css';
import './assets/scss/icons.scss';
import './assets/scss/app.scss';

//imoprt Route
import Route from './Routes';

function App() {
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
