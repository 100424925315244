import React from "react";
import { Navigate } from "react-router-dom";

import Survey from "../pages/Survey";

const publicRoutes = [
  {
    path: "/",
    exact: true,
    component: <Survey />,
  },
  { path: "*", component: <Navigate to="/" /> },
];

export { publicRoutes };