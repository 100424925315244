import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  SUBMIT_SURVEY,
} from "./actionTypes";

import {
  submitSurveySuccess,
  submitSurveyFail,
} from "./actions";

import { APIBackend } from "../../helpers/api_helper";

const api = new APIBackend();

function* onSubmitSurvey({ payload: { survey } }) {
  try {
    const response = yield call(api.submitSurvey, survey);
    yield put(submitSurveySuccess(response));
  } catch (error) {
    yield put(submitSurveyFail(error));
  }
}

export function* watchSubmitSurvey() {
  yield takeEvery(SUBMIT_SURVEY, onSubmitSurvey);
}

function* SurveySaga() {
  yield all([fork(watchSubmitSurvey)]);
}

export default SurveySaga;