import * as url from "./url_helper";

class APIBackend {
  constructor() {

  }

  submitSurvey = (data) => {
    return new Promise((resolve, reject) => {
      fetch(url.POST_SUBMIT_SURVEY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then(
          response => {
            response.json().then(
              res => {
                if (res.ok) {
                  resolve(res);
                } else {
                  reject(this._handleError(res.error));
                }
              }
            )
          },
        )
        .catch(error => {
          reject(this._handleError(error));
        })
    });
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    var errorCode = error.code;
    var errorMessage = error.message;
    return {
      'code': errorCode,
      'message': errorMessage,
    };
  }
}

export { APIBackend };