import {
  SUBMIT_SURVEY,
  SUBMIT_SURVEY_SUCCESS,
  SUBMIT_SURVEY_FAIL,
} from "./actionTypes"

export const submitSurvey = (survey) => ({
  type: SUBMIT_SURVEY,
  payload: { survey },
})

export const submitSurveySuccess = () => ({
  type: SUBMIT_SURVEY_SUCCESS,
})

export const submitSurveyFail = (error) => ({
  type: SUBMIT_SURVEY_FAIL,
  payload: { error },
})