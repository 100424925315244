import {
  SUBMIT_SURVEY,
  SUBMIT_SURVEY_SUCCESS,
  SUBMIT_SURVEY_FAIL,
} from "./actionTypes"

const initialState = {
  survey: {},
  submitLoading: false,
  submitSuccess: false,
  error: null,
};

const survey = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_SURVEY:
      return {
        ...state,
        submitLoading: true,
        submitSuccess: false,
        error: null,
      };
    
    case SUBMIT_SURVEY_SUCCESS:
      return {
        ...state,
        submitLoading: false,
        submitSuccess: true,
        error: null,
      };
    
    case SUBMIT_SURVEY_FAIL:
      return {
        ...state,
        submitLoading: false,
        submitSuccess: false,
        error: null,
      };
      
    default:
      return { ...state };
  }
};

export default survey;